import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Hero = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "hero" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
            }
          }
        }
      }
    }
  `);
  const { node } = allMarkdownRemark.edges[0];

  return (
    <div className="Hero">
      <h1 className="Hero__title">{node.frontmatter.title}</h1>
      <div
        className="Hero__paragraph"
        dangerouslySetInnerHTML={{ __html: node.frontmatter.description }}
      />
      <a className="Hero__button" href="#kontakt">
        Napište nám
      </a>
    </div>
  );
};

export default Hero;
