import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const About = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "about" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
            }
          }
        }
      }
    }
  `);
  const { node } = allMarkdownRemark.edges[0];

  return (
    <section className="About">
      <div className="About__container">
        <div className="About__desc">
          <h2 className="About__title">{node.frontmatter.title}</h2>
          <div
            className="About__text"
            dangerouslySetInnerHTML={{ __html: node.frontmatter.description }}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
