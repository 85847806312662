import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";

const Benefits = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "benefits" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(width: 200)
                }
              }
            }
          }
        }
      }
    }
  `);
  const benefits = allMarkdownRemark.edges.slice(0, 3);

  return (
    <section className="Benefits">
      {benefits.map(({ node }) => (
        <div key={node.id} className="Benefits__card">
          <Img image={getImage(node.frontmatter.image)} />
          <div className="Benefits__desc">
            <h3 className="Benefits__title">{node.frontmatter.title}</h3>
            <div
              className="Benefits__text"
              dangerouslySetInnerHTML={{ __html: node.frontmatter.description }}
            />
          </div>
        </div>
      ))}
    </section>
  );
};

export default Benefits;
