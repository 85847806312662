import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { decode } from "../js/entities";

const Services = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "services" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              capacity
              accessories
              volume
              image {
                childImageSharp {
                  gatsbyImageData(width: 270)
                }
              }
            }
          }
        }
      }
    }
  `);
  const data = allMarkdownRemark.edges;
  const showExtendButton = data.length > 6;

  return (
    <section id="technika" className="Services">
      <div className="Services__titleWrapper">
        <h2 className="Services__title">Technika</h2>
      </div>
      <div className="Services__grid">
        {data.slice(0, 6).map(({ node }) => (
          <div key={node.id} className="Services__item">
            <h3 className="Services__item-title">
              {decode(node.frontmatter.title)}
            </h3>
            <Link className="Services__item-imgWrapper" to="/cenik">
              <GatsbyImage
                className="Services__item-img"
                image={getImage(node.frontmatter.image)}
                objectFit="cover"
              />
              <div className="Services__item-imgOverlay">
                <div className="Services__item-imgButton">Zjistit cenu</div>
              </div>
            </Link>
            <ul className="Services__item-list">
              {node.frontmatter.accessories && (
                <li className="Services__item-li">
                  {node.frontmatter.accessories}
                </li>
              )}
              {node.frontmatter.capacity && (
                <li className="Services__item-li">
                  nosnost {node.frontmatter.capacity}
                </li>
              )}
              {node.frontmatter.volume && (
                <li className="Services__item-li">
                  objem korby {node.frontmatter.volume}
                </li>
              )}
            </ul>
          </div>
        ))}
      </div>
      {showExtendButton && (
        <Link to="/cenik" className="Hero__button Services__extend">
          Další technika
        </Link>
      )}
    </section>
  );
};

export default Services;
