import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Benefits from "../components/benefits";
import About from "../components/about";
import Services from "../components/services";

const IndexPage = () => (
  <Layout path="root">
    <SEO title="Domovská stránka" />
    <Hero />
    <Benefits />
    <About />
    <Services />
  </Layout>
);

export default IndexPage;
